.navbar {
    background-color: #822892 !important;
    width: 100% !important;
    min-width: 100% !important;
}

@media only screen and (max-device-width: 400px) {
    .story-box {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
    }
}

@media all {
    .navbar {
        width: 100% !important;
        min-width: 100% !important;
    }

    html, body {
        min-width: 100% !important;
        max-width: 100% !important;
        overflow-x: hidden !important;
    }
}

.story-box {
    border: 1px solid #cecece;
    border-radius: 5px;
    padding: 5px;
    float: left;
    margin: 15px;
    width: 350px;
    max-width: 350px;
    min-width: 350px;
    height: 20vh;
    max-height: 20vh;
    min-height: 20vh;
}

.story-page {
    border: 1px solid #cecece;
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 5px;
}

.post-page-file {
    width: 100%;
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    border: 1px solid #d7d7d7;
    margin-top: 10px;
}

.story-box:hover {
    border: 1px solid #822892;
    box-shadow: 0 0 5px #bdbdbd;
}

.story-body {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical;
    cursor: pointer;

    height: 8vh !important;
    min-height: 8vh !important;
    max-height: 8vh !important;
}

.tags {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1 !important;
    -webkit-box-orient: vertical;
}

.comment-box {
    margin: 4px;
    padding: 2px;
    border-bottom: 1px solid #cecece;
}

.h5 {
    margin-left: 15px;
}

.top {
    border: 1px solid #822892;
}

.purple {
    color: #822892;
}
.btn-purple {
    color: #822892;
    cursor: pointer;
}

.top-posts {
    padding-right: 0px !important;
    padding-left: 0px !important;
}

.file-side {
    width: 33%;
}

.body-side {
    width: 66%;
}

@media only screen and (max-width: 600px) {
    .div-login {
        width: 100% !important;
    }

    .div-signup {
        width: 100% !important;
    }
}

.badge {
    font-weight: 400;
}

.badge-light {
    background-color: #e9e9e9;
}

.btn-light {
    background-color: #e9e9e9;
}

.badge-vote {
    color: #822892;
    cursor: pointer;
}

.post-file {
    border: 1px solid #d7d7d7;
    border-radius: 5px;
    width: 75px;
    height: 45px;
    cursor: pointer;
}

.post-file-big {
    width: 100%;
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    border: 1px solid #d7d7d7;
}

.app-modal-content {
    border: none;
    border-radius: 0px;
    width: 100%;
    height: 100%;
}

.not-found {
    margin-left: 15px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-30 {
    margin-top: 30px;
}
.mb-10 {
    margin-bottom: 10px;
}

/****
TREE
 */
ul, ol {margin: 0; padding: 0;}
li {list-style: none; line-height: 1.6rem;}

/* List styling */
.jslists { width: 100% !important; overflow: auto;}
.jslists a { cursor: pointer; margin-right: 0px;}
.jslists a:hover { text-decoration: underline; color: blue;}
.jslist-ul, .jslist-ol, .jslist-li {margin-left: 15px;}		/* Unordered lists */
.jsl-collapsed {display: none;}
.jsl-list-closed {
    float: left;
    clear: both;
    margin: 2px 4px 2px 0px;
    width: 18px;
    height: 18px;
    cursor: pointer;
    background-image: url('data:image/svg+xml;utf8,<svg aria-hidden="true" data-prefix="far" data-icon="plus-square" class="svg-inline--fa fa-plus-square fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M352 240v32c0 6.6-5.4 12-12 12h-88v88c0 6.6-5.4 12-12 12h-32c-6.6 0-12-5.4-12-12v-88h-88c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h88v-88c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v88h88c6.6 0 12 5.4 12 12zm96-160v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48zm-48 346V86c0-3.3-2.7-6-6-6H54c-3.3 0-6 2.7-6 6v340c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z"></path></svg>');
    background-repeat: no-repeat;
    background-position: center;
}
.jsl-open {display: block;}
.jsl-list-open {background-image: url('data:image/svg+xml;utf8,<svg aria-hidden="true" data-prefix="far" data-icon="minus-square" class="svg-inline--fa fa-minus-square fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M108 284c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h232c6.6 0 12 5.4 12 12v32c0 6.6-5.4 12-12 12H108zM448 80v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48zm-48 346V86c0-3.3-2.7-6-6-6H54c-3.3 0-6 2.7-6 6v340c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z"></path></svg>');}
.jslists i {margin-right: 5px; margin-left: 8px; cursor: pointer; color: #a0a0a0;}
.jslists i:hover {color: #666666;}
.jslists > li {margin-left: 0px;}
.not-selected {font-size: 50px; color: #a0a0a0; margin-top: 20%;}
.not-selected-hint {font-size: 20px; color: #a0a0a0;}

.select-search {width: 100% !important;}